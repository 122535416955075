import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuthToken } from "../../auth/authToken";

export function useResetPasswordMutation() {
  const { strapiUrl } = useAuthToken();
  return useMutation({
    mutationFn: async (email: string) => {
      const x = await axios.post<any>(`${strapiUrl}/api/auth/forgot-password`, {
        email: email,
      });
      return x.data;
    },
  });
}
