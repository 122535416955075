import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, Typography } from "@mui/material";
import { ChangeEvent, DragEvent, RefObject, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { formatBytes } from "../../../utils/sizeConverting";

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  iconText?: string;
  icon?: any;
  iconType?: string;
}

const setFile = (
  e: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
  file: File | null,
  onChange: (o: File) => void,
  inputRef: RefObject<HTMLInputElement>
) => {
  e.preventDefault();
  e.stopPropagation();

  if (inputRef.current?.value) {
    inputRef.current.value = "";
  }

  if (file) {
    onChange(file);
  }
};

const FileUploader: React.FC<Props> = ({
  control,
  name,
  label,
  iconText,
  icon,
  iconType = "warning",
}: Props) => {
  const [, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = async function (
    e: DragEvent<HTMLDivElement>,
    onChange: (o: File) => void
  ) {
    setFile(e, e.dataTransfer.files[0], onChange, inputRef);
  };

  const handleChange = async function (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (o: File) => void
  ) {
    if (e.target.files === null) return;
    setFile(e, e.target.files[0], onChange, inputRef);
  };

  const onButtonClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <FormControl fullWidth sx={{ margin: "8px 0 8px 0" }}>
              {label && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "left",
                    marginBottom: "2px",
                  }}
                >
                  {label}
                </Typography>
              )}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80%",
                }}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={(e) => handleDrop(e, onChange)}
              >
                <input
                  ref={inputRef}
                  type="file"
                  accept=".pdf, .png, .jpeg, .jpg, .tiff, .doc, .docx"
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e, onChange)}
                />
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: value?.length > 0 ? 2 : 5,
                    alignItems: "center",
                    justifyContent: "center",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    backgroundColor: "#EAF1EB",
                    border: "2px dashed #2A713A",
                    padding: value?.length > 0 ? "5px 25px 5px 25px" : "25px",
                  }}
                  htmlFor="input-file-upload"
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    {iconText}
                  </Typography>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor:
                        iconType === "warning"
                          ? "#FBE5C1"
                          : iconType === "error"
                          ? "#D92D20"
                          : "initial",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor:
                          iconType === "warning"
                            ? "#FFAD01"
                            : iconType === "error"
                            ? "#F04438"
                            : "initial",

                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={icon || "/assets/upload-icon.png"}
                        alt="Upload"
                        style={{
                          maxWidth: "80%",
                          maxHeight: "80%",
                        }}
                      />
                    </div>
                  </div>
                  <Box sx={{ display: "flex", gap: 1, paddingX: 12 }}>
                    <button
                      type="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={onButtonClick}
                    >
                      <Typography
                        variant="subtitle1"
                        color="success.main"
                        sx={{ whiteSpace: "nowrap", fontWeight: 600 }}
                      >
                        Click to upload
                      </Typography>
                    </button>
                    <Typography
                      variant="subtitle1"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      or drag and drop
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </Typography>
                </label>
              </Box>
              {value && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    padding: 2,
                    height: "20%",
                    width: "70%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      borderRadius: 3,
                      bgcolor: "#FFFFFF",
                      padding: 1,
                      gap: 3,
                      borderWidth: "2px",
                    }}
                  >
                    <img src="/assets/file-icon.png" alt="File" />
                    <Box>
                      <Typography variant="body2">{value?.name}</Typography>
                      <Typography variant="body2">
                        {value?.size > 0 ? formatBytes(value?.size) : ""}
                      </Typography>
                    </Box>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => onChange(null)}
                    />
                  </Box>
                </Box>
              )}
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default FileUploader;
