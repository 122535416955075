import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../components/snackbar/Snackbar";

interface Data {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zipCode: string;
  username: string;
  phoneNumber: string;
  countryDialCodeNumber: string;
  password: string;
  interestSource: string;
  token: string;
}

const parseJsonResponse = async <TResponse>(
  response: Response
): Promise<TResponse> => {
  if (response.ok) {
    return (await response.json()) as TResponse;
  } else if (response.status === 401) {
    throw new Error("Authentification failed");
  }
  const errorMessage = await response.text();
  throw new Error(errorMessage);
};

export function useRegisterMutation() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (data: Data) => {
      return fetch(`${process.env.REACT_APP_MC_URL}/api/register`, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(parseJsonResponse<Response>);
    },
    onSuccess: (response) => {
      navigate("/login");
      showSnackbar(
        "success",
        "Please check email to verify your account.",
        5000,
        {
          vertical: "bottom",
          horizontal: "center",
        }
      );
    },
    onError: (error) => {
      showSnackbar("error", error.message.replace(/^"|"$/g, ""), 3000, {
        vertical: "bottom",
        horizontal: "center",
      });
    },
  });
}
