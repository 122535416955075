import type { QuestionType } from "../../types/onboarding/onboarding";

export const farmerProcessOneQuestions: QuestionType[] = [
  {
    id: "processOneData.UD-I1-FN",
    serialNum: "1",
    questionType: "textInput",
    text: "First Name",
    options: [],
    placeholder: "Enter first name",

    condition: null,
  },
  {
    id: "processOneData.UD-I1-LN",
    serialNum: "2",
    questionType: "textInput",
    text: "Last Name",
    options: [],
    placeholder: "Enter last name",

    condition: null,
  },
  {
    id: "processOneData.UD-I2",
    serialNum: "3",
    questionType: "textInput",
    text: "Email",
    options: [],
    placeholder: "example@email.com",

    condition: null,
  },
  {
    id: "processOneData.UD-I3",
    serialNum: "4",
    questionType: "textInput",
    text: "Phone Number",
    options: [],
    placeholder: "1234 5678",

    condition: null,
  },
  {
    id: "processOneData.UD-I4",
    serialNum: "5",
    questionType: "radioButton",
    text: "Interest Source",
    placeholder: "",
    options: ["Social media", "Forums", "Word of mouth"],

    condition: null,
  },
];

export const farmerProcessTwoQuestions: QuestionType[] = [
  {
    id: "processTwoData.FJ-G1",
    serialNum: "1",
    // processNumber:1,s
    questionType: "chipChecklist",
    text: "Short-Term Goals",
    options: [
      "Improve profits",
      "Improve operational efficiency",
      "Inset emissions",
      "Invest in new infrastructure",
      "Enhance resource utilization",
      "Implement sustainable pest and disease management",
      "Diversify production or product offerings",
      "Test innovative practices or technologies",
      "Strengthen workforce skills",
      "Expand market reach",
      "Build local networks and partnerships",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-G2",
    serialNum: "2",
    questionType: "chipChecklist",
    text: "Long-Term Goals",
    options: [
      "Achieve long-term financial stability",
      "Enhance overall farm resilience",
      "Adopt regenerative agriculture practices",
      "Transition to certified production",
      "Expand farm size or scale",
      "Develop a strong farm brand and reputation",
      "Enhance succession planning",
      "Foster innovation and research",
      "Strengthen community engagement",
      "Promote environmental stewardship",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-V1",
    serialNum: "3",
    questionType: "radioButton",
    text: "Beginning Confidence In Regenerative Agriculture",
    options: [
      "Not confident",
      "Slightly confident",
      "Moderately confident",
      "Highly confident",
      "Fully confident",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-V2",
    serialNum: "4",
    questionType: "chipChecklist",
    text: "Beginning Farmer Values",
    options: [
      "Environmental stewardship",
      "Soil health",
      "Sustainable agriculture",
      "Climate resilience",
      "Economic viability",
      "Farm productivity",
      "Family legacy",
      "Rural community vitality",
      "Food security",
      "Animal welfare",
      "Innovation and technology adoption",
      "Education and knowledge sharing",
      "Collaboration and networking",
      "Market access and diversification",
      "Recognition and reputation",
      "Personal fulfillment and work-life balance",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-D1",
    serialNum: "5",
    questionType: "radioButton",
    text: "Interest In Credits",
    options: [
      "No Interest",
      "Not right now but maybe later",
      "Carbon credits",
      "Water quality credits",
      "Conservation credits",
      "Biodiversity credits",
      "Environmental stewardship credits",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-D2",
    serialNum: "6",
    questionType: "radioButton",
    text: "Interest In Certifications",
    options: [
      "No Interest",
      "No but maybe later",
      "Carbon8 Verified Regenerative (Australia)",
      "Fresh Earth Verified Regenerative (FEVR) (Global)",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-E1",
    serialNum: "7",
    questionType: "radioButton",
    text: "Regenerative Agriculture Knowledge",
    options: [
      "No Knowledge of Regenerative Agriculture",
      "Minimal Knowledge of Regenerative Agriculture",
      "Basic Understanding of Regenerative Agriculture",
      "Solid Knowledge of Regenerative Agriculture",
      "Comprehensive Knowledge of Regenerative Agriculture",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FJ-C1",
    serialNum: "8",
    questionType: "chipChecklist",
    text: "Beginning Challenges Faced In Managing Farm",
    options: [
      "Soil health and fertility",
      "Water management",
      "Pest and disease control",
      "Climate change and extreme weather",
      "Labour availability and cost",
      "Market access and volatility",
      "Input costs",
      "Access to capital and financing",
      "Technological challenges",
      "Regulatory compliance",
      "Land access and tenure",
      "Infrastructure",
      "Biodiversity and ecosystem services",
      "Social and community issues",
      "Knowledge and skills",
    ],
    placeholder: "",

    condition: null,
  },
  {
    id: "processTwoData.FM-SP1",
    serialNum: "9",
    questionType: "radioButton",
    text: "Beginning Regenerative Agriculture Practices",
    options: [
      "No Regenerative Practices",
      "Stopped Regenerative Practice",
      "Regenerative Practices Implemented",
    ],
    placeholder: "",

    condition: null,
    children: [
      {
        id: "processTwoData.2-FM-SP1-1-FJ-C1",
        serialNum: "9a",
        questionType: "chipChecklist",
        text: "Transition Doubts",
        options: [
          "Adequacy of support and resources",
          "Uncertain financial benefits",
          "Lack of reliable information",
          "Time-consuming transition",
          "Concerns about crop yields",
          "Difficulty in measuring success",
          "Scepticism from peers",
          "Perceived risk of failure",
          "Concerns about labour requirements",
          "Compatibility with existing systems",
          "Resistance to change",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "No Regenerative Practices",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-2-FJ-C1",
        serialNum: "9a",
        questionType: "chipChecklist",
        text: "Reason For Stopping Regenerative Program",
        options: [
          "Insufficient support and guidance",
          "Financial constraints",
          "Lack of immediate results",
          "Labor and time demands",
          "Limited knowledge and skills",
          "Market pressures",
          "Regulatory barriers",
          "Social and cultural resistance",
          "Climate and weather challenges",
          "Pest and disease pressures",
          "Perceived trade-offs",
          "Limited access to appropriate inputs",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "Stopped Regenerative Practice",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-2-FM-SP1",
        serialNum: "9b",
        questionType: "chipChecklist",
        text: "Previous Regenerative Practices",
        options: [
          "No-till or reduced-till farming",
          "Cover cropping",
          "Crop rotation",
          "Agroforestry",
          "Holistic planned grazing",
          "Silvopasture",
          "Composting and incorporating organic matter",
          "Conservation tillage",
          "Mulching",
          "Intercropping",
          "Perennial cropping systems",
          "Living pathways or alley cropping",
          "Keyline ploughing",
          "Water harvesting and management",
          "Hedgerows and windbreaks",
          "Riparian buffers",
          "Pollinator habitat",
          "Integrated pest management",
          "Biochar application",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "Stopped Regenerative Practice",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-3-FJ-C1",
        serialNum: "9a",
        questionType: "chipChecklist",
        text: "Transition Barriers",
        options: [
          "Financial constraints",
          "Limited knowledge and skills",
          "Insufficient support and guidance",
          "Market pressures",
          "Labour and time demands",
          "Regulatory barriers",
          "Climate and weather challenges",
          "Pest and disease pressures",
          "Limited access to appropriate inputs",
          "Infrastructure and equipment requirements",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "Regenerative Practices Implemented",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-3-FM-SP1",
        serialNum: "9b",
        questionType: "chipChecklist",
        text: "Beginning Regenerative Practices",
        options: [
          "No-till or reduced-till farming",
          "Cover cropping",
          "Crop rotation",
          "Agroforestry",
          "Holistic planned grazing",
          "Silvopasture",
          "Composting and incorporating organic matter",
          "Conservation tillage",
          "Mulching",
          "Intercropping",
          "Perennial cropping systems",
          "Living pathways or alley cropping",
          "Keyline ploughing",
          "Water harvesting and management",
          "Hedgerows and windbreaks",
          "Riparian buffers",
          "Pollinator habitat",
          "Integrated pest management",
          "Biochar application",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "Regenerative Practices Implemented",
        },
      },
    ],
  },

  {
    id: "processTwoData.IK-1",
    serialNum: "10",
    questionType: "radioButton",
    text: "Indigenous Knowledge Receptivity",
    options: [
      "Unsure",
      "Not interested",
      "Mildly interested",
      "Very interested",
      "Have already incorporated",
    ],
    placeholder: "",

    condition: null,
  },
];

export const farmerProcessThreeQuestions: QuestionType[] = [
  {
    id: "processThreeData.UD-I1",
    serialNum: "1",
    questionType: "textInput",
    text: "Legal Trading Entity Name",
    options: [],
    placeholder: "Insert a correct entry",

    condition: null,
  },
  {
    id: "processThreeData.UD-I2",
    serialNum: "2",
    questionType: "textInput",
    text: "Legal Business Name",
    options: [],
    placeholder: "Insert a correct entry",

    condition: null,
  },
  {
    id: "processThreeData.UD-I3",
    serialNum: "3",
    questionType: "textInput",
    text: "Business Number",
    options: [],
    placeholder: "Insert a correct entry",

    condition: null,
  },
  {
    id: "processThreeData.UD-MA1",
    serialNum: "4",
    questionType: "chipChecklist",
    text: "Current Certifications",
    options: [
      "Australian Certified Organic (ACO)",
      "National Association for Sustainable Agriculture Australia (NASAA)",
      "Demeter Biodynamic Certification",
      "Fairtrade Australia and New Zealand",
      "Rainforest Alliance Certified",
      "UTZ Certified",
      "Forest Stewardship Council (FSC) certification",
      "Marine Stewardship Council (MSC) certification",
      "Certified Sustainable Seafood (CSS) certification",
      "ISO 14001 Environmental Management System certification",
      "Safe Food Production Queensland (SFPQ) certification",
      "Freshcare certification",
      "SQF (Safe Quality Food) certification",
      "HACCP (Hazard Analysis and Critical Control Points) certification",
      "Food Safety System Certification (FSSC 22000)",
      "GlobalG.A.P. certification",
      "Roundtable on Sustainable Palm Oil (RSPO) certification",
      "Responsible Wool Standard (RWS) certification",
      "Better Cotton Initiative (BCI) certification",
      "Certified Grassfed by the Pasturefed Cattle Assurance System (PCAS)",
      "FarmGate MSQA (Meat Safety and Quality Assurance) certification",
      "Organic & Biodynamic Certification Australia (OBCA)",
      "EU Organic certification",
      "JAS-ANZ Organic certification",
      "NASAA Certified Organic (NCO)",
      "Organic Food Chain (OFC) certification",
    ],
    placeholder: "",

    condition: null,
    children: [
      {
        id: "processThreeData.3-UD-MA1-UD-MA1",
        serialNum: "4a",
        questionType: "media",
        text: "Proof of Farmer's current certification/verifications",
        options: [],
        placeholder: "",
        condition: {
          parentQuestion: "processThreeData.UD-MA1",
          rule: "notEmpty",
        },
      },
    ],
  },

  {
    id: "processThreeData.UD-MA3",
    serialNum: "5",
    questionType: "chipChecklist",
    text: "Previous Certifications",
    options: [
      "Australian Certified Organic (ACO)",
      "National Association for Sustainable Agriculture Australia (NASAA)",
      "Demeter Biodynamic Certification",
      "Fairtrade Australia and New Zealand",
      "Rainforest Alliance Certified",
      "UTZ Certified",
      "Forest Stewardship Council (FSC) certification",
      "Marine Stewardship Council (MSC) certification",
      "Certified Sustainable Seafood (CSS) certification",
      "ISO 14001 Environmental Management System certification",
      "Safe Food Production Queensland (SFPQ) certification",
      "Freshcare certification",
      "SQF (Safe Quality Food) certification",
      "HACCP (Hazard Analysis and Critical Control Points) certification",
      "Food Safety System Certification (FSSC 22000)",
      "GlobalG.A.P. certification",
      "Roundtable on Sustainable Palm Oil (RSPO) certification",
      "Responsible Wool Standard (RWS) certification",
      "Better Cotton Initiative (BCI) certification",
      "Certified Grassfed by the Pasturefed Cattle Assurance System (PCAS)",
      "FarmGate MSQA (Meat Safety and Quality Assurance) certification",
      "Organic & Biodynamic Certification Australia (OBCA)",
      "EU Organic certification",
      "JAS-ANZ Organic certification",
      "NASAA Certified Organic (NCO)",
      "Organic Food Chain (OFC) certification",
    ],
    placeholder: "",

    condition: null,
    children: [
      {
        id: "processThreeData.3-UD-MA3-UD-MA1",
        serialNum: "5a",
        questionType: "media",
        text: "Proof of Farmer's previous certification/verifications",
        options: [],
        placeholder: "",
        condition: {
          parentQuestion: "processThreeData.UD-MA3",
          rule: "notEmpty",
        },
      },
    ],
  },

  {
    id: "processThreeData.UD-MA2",
    serialNum: "6",
    questionType: "radioButton",
    text: "Regen Farmers Mutual Membership",
    options: ["Yes", "No"],
    placeholder: "Insert a correct entry",

    condition: null,
  },
  {
    id: "processThreeData.FJ-E1",
    serialNum: "7",
    questionType: "chipChecklist",
    text: "Beginning Farmer Training",
    options: [
      "Regenerative Agriculture Masterclass",
      "Holistic Management International",
      "RegenAG",
      "Land to Market Australia",
      "Carbon Farming Initiative (CFI)",
      "Future Farmers Network Regenerative Agriculture Scholarship",
    ],
    placeholder: "",

    condition: null,
  },
];
