import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userManagementAxios } from "../../config/axios-instance";

interface BlockUserData {
  email: string;
  blocked: boolean;
}

export function useBlockUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: BlockUserData) => {
      const result = await userManagementAxios.post(`api/users/block`, data);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users/all"] });
    },
  });
}
