import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { User } from "../../types/user";
import { userManagementAxios } from "../../config/axios-instance";

export function useAllUsersQuery() {
  const { tokenHeaders, hasToken, strapiUrl } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: ["/users/all"],
    queryFn: () =>
      fetch(`${strapiUrl}/api/users?populate=deep,2`, {
        headers: tokenHeaders,
      }).then(parseJsonResponse<User[]>),
    enabled: hasToken,
  });
}

type UseAllUsersQueryProps = {
  searchText?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const useAllUsersInfiniteQuery = ({
  searchText,
  pageSize,
  pageNumber,
}: UseAllUsersQueryProps) => {
  return useInfiniteQuery({
    queryKey: ["users/all", searchText],
    queryFn: async ({ pageParam = 1 }) => {
      const queryParameters = `page=${pageParam}&pageSize=${pageSize || 10}`;

      const filters = [searchText ? `searchTerm=${searchText}` : null]
        .filter(Boolean)
        .join("&");

      const endpoint = `/api/users/all?${queryParameters}${
        filters ? `&${filters}` : ""
      }`;

      const response = await userManagementAxios.get(endpoint);
      return response.data;
    },
    initialPageParam: pageNumber || 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
};
