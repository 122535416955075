import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { ProgramOverview } from "../../types/programs/ProgramsType";

export const useProgramOverviewQuery = (id: number) => {
  return useQuery({
    queryKey: ["program-overview", id],
    queryFn: async () => {
      const result = await coreServiceAxios.get<ProgramOverview>(
        `/api/program-overview/${id}`
      );
      return result.data;
    },
  });
};
