import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useEditTemplateProgramMutation } from "../../../api/programs/template/editTemplateProgramMutation";
import { editTemplateProgramSchema } from "../../../schemas/template-programs";
import {
  BackToButton,
  ReusableButton,
  ReusableImageUpload,
  ReusableSelect,
  ReusableTextField,
} from "../../reusable";
import { SectionHeader } from "../widgets";
import Protocols from "./protocols/Protocols";

type Props = {
  data: any;
  id: string;
};

const EditProgramPage = ({ data, id }: Props) => {
  const { mutateAsync: editProgram, isPending } =
    useEditTemplateProgramMutation();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(editTemplateProgramSchema),
  });

  const { handleSubmit, reset, control } = form;

  const onSubmit = async (value: any) => {
    if (isPending) return;

    await editProgram({ id: Number(id), data: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "20px 40px 40px 40px",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "1440px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <BackToButton
            label="Programs"
            onBackTo={() => navigate("/programs")}
          />
        </Box>
        <SectionHeader
          title={"Program Summary"}
          subtitle={"Edit program summary"}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            width: "100%",
            marginTop: "24px",
          }}
        >
          <Box sx={{ width: "50%", textAlign: "center" }}>
            <ReusableImageUpload
              form={form}
              name="program.image"
              label="Program Image"
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <ReusableTextField
              control={control}
              name="program.title"
              inputType="text"
              label="Program Name"
              placeholder="Name"
            />
            <ReusableSelect
              control={control}
              name="program.category"
              options={[
                "Biochar",
                "Pasture Renovation",
                "Ph and Structure Renovation",
              ]}
              label="Program Category"
              placeholder="Select program category"
            />
            <ReusableTextField
              control={control}
              name="program.description"
              inputType="text"
              label="Description"
              placeholder="Enter a description..."
              multiline={true}
              rows={5}
            />
          </Box>
        </Box>
        <Protocols form={form} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <ReusableButton
            buttonText={"Reset Changes"}
            color="#1C5553"
            backgroundColor="#D7E2E2"
            width="20%"
            beforeIcon={<img src="/assets/reset-icon.svg" alt="reset" />}
            onClickFunction={() => {
              reset();
            }}
          />

          <ReusableButton
            buttonText={"Save Program"}
            width="20%"
            onClickFunction={handleSubmit(onSubmit)}
            isLoading={isPending}
            // disabled={!hasChanges || isPending}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditProgramPage;
