import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { ContactData } from "../../types/account-details";
import { userManagementAxios } from "../../config/axios-instance";

export const useEditUserMutation = () => {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: ContactData }) => {
      const response = await userManagementAxios.put(
        `api/users/edit/${id}`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["/users/all"] });
      queryClient.invalidateQueries({ queryKey: [tokenHeaders, "/me"] });
    },
  });
};
