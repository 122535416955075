import { yupResolver } from "@hookform/resolvers/yup";
import {
  EmailOutlined,
  Person2Outlined,
  PersonPinOutlined,
} from "@mui/icons-material";
import { Box, Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ArrowForward } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useRegisterMutation } from "../api/users/registerMutation";
import {
  CountryDialCodeSelect,
  Header,
  PasswordTextField,
  PrivacyPolicy,
} from "../components/registration";
import ErrorScreen from "../components/registration/ErrorScreen";
import {
  ReusableAutocomplete,
  ReusableButton,
  ReusableRadioButtons,
  ReusableTextField,
} from "../components/reusable";
import { useSnackbar } from "../components/snackbar/Snackbar";
import { countries, type CountryType } from "../constants/countries";
import {
  interestSourceOptions,
  registerPageCustomTheme,
} from "../constants/registration";
import {
  RegisterDataDefaultValues,
  defaultCountry,
  registrationSchema,
} from "../schemas/registration";
import type { RegisterDataType } from "../types/registration/RegisterDataType";

export const renderCountryOption = (props: any, option: any) => (
  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
    <img
      loading="lazy"
      width="20"
      srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
      src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
      alt=""
      onError={(e) => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.style.display = "none";
      }}
    />
    {option.label}
  </Box>
);

const RegisterPage = () => {
  const [countryDialCode, setCountryDialCode] =
    useState<CountryType>(defaultCountry);
  const [isLoading, setIsLoading] = useState(false);
  const [hasValidToken, setHasValidToken] = useState<boolean>(false);
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);

  const { mutate } = useRegisterMutation();
  const { showSnackbar } = useSnackbar();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const code = params.get("code");

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: RegisterDataDefaultValues,
    resolver: yupResolver(registrationSchema),
  });

  const verifyInvitationToken = useCallback(
    async (token: string) => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_MC_URL}/api/register/verifyinvitationtoken`,
          {
            body: JSON.stringify({ token }),
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 200) {
          const responseData = await res.json();
          if (!responseData.data.email) {
            setShowErrorScreen(true);
            return;
          }
          setValue("email", responseData.data.email);
          setHasValidToken(true);
        } else {
          setShowErrorScreen(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setShowErrorScreen(true);
      }
    },
    [setValue]
  );

  const onSubmit = async (value: RegisterDataType) => {
    if (isLoading) return;
    setIsLoading(true);
    const {
      confirmPassword,
      country,
      phoneNumber,
      countryDialCodeNumber,
      ...rest
    } = value;

    const object = {
      ...rest,
      country: value.country.label,
      countryDialCodeNumber: `${countryDialCode.phone}`,
      phoneNumber,
      token: code ?? "",
    };
    try {
      mutate(object);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("error", "Something went wrong.", 3000);
    }
  };

  useEffect(() => {
    if (code) {
      verifyInvitationToken(code);
    } else {
      setShowErrorScreen(true);
    }
  }, [code, verifyInvitationToken]);

  return (
    <ThemeProvider theme={registerPageCustomTheme}>
      {hasValidToken && (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Header />
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              sx={{ display: "flex", flexDirection: "column", mt: 1 }}
            >
              <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
                <ReusableTextField
                  inputType="text"
                  control={control}
                  name="firstName"
                  label="First Name"
                  placeholder="Enter first name"
                  autoComplete="firstName"
                  isRequired={true}
                  autofocus={true}
                  startAdornment={<Person2Outlined />}
                />
                <ReusableTextField
                  inputType="text"
                  control={control}
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter last name"
                  autoComplete="lastName"
                  isRequired={true}
                  startAdornment={<Person2Outlined />}
                />
              </Box>
              <ReusableTextField
                inputType="email"
                control={control}
                name="email"
                label="Email"
                placeholder="example@email.com"
                autoComplete="email"
                isRequired={true}
                startAdornment={<EmailOutlined />}
                disabled={true}
              />
              <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
                <ReusableAutocomplete
                  name="country"
                  label="Country"
                  control={control}
                  options={countries.map((country) => ({
                    id: country.code,
                    label: country.label,
                  }))}
                  renderOption={renderCountryOption}
                  searchIcon={true}
                />
                <ReusableTextField
                  inputType="text"
                  control={control}
                  name="zipCode"
                  label="Zip Code"
                  placeholder="123"
                  isRequired={true}
                  tooltipText="Zip code of your main property"
                />
              </Box>
              <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
                <ReusableTextField
                  inputType="text"
                  control={control}
                  name="username"
                  label="Username"
                  autoComplete="username"
                  isRequired={true}
                  // tooltipText="Username"
                  startAdornment={<PersonPinOutlined />}
                />
                <ReusableTextField
                  inputType="text"
                  control={control}
                  name="phoneNumber"
                  label="Phone Number"
                  autoComplete="phoneNumber"
                  isRequired={true}
                  // tooltipText="Phone Number"
                  startAdornment={
                    <CountryDialCodeSelect
                      chosenCountry={countryDialCode}
                      setChosenCountry={setCountryDialCode}
                    />
                  }
                />
              </Box>
              <PasswordTextField
                inputType="password"
                control={control}
                name="password"
                id="password"
                label="Password"
                autoComplete="password"
              />
              <ReusableTextField
                inputType="password"
                control={control}
                name="confirmPassword"
                label="Confirm Password"
                autoComplete="confirmPassword"
              />
              <ReusableRadioButtons
                name="interestSource"
                control={control}
                options={interestSourceOptions}
                label=" How did you hear about Fresh Earth?"
              />
              <Box
                sx={{
                  mt: 1,
                  mb: 1,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <ReusableButton
                  onClickFunction={handleSubmit(onSubmit)}
                  buttonText="Register"
                  color="#ffff"
                  backgroundColor="#FFAD01"
                  afterIcon={<ArrowForward />}
                  isLoading={isLoading}
                />
              </Box>
            </Box>
          </Box>
          <PrivacyPolicy />
        </Container>
      )}
      {showErrorScreen && <ErrorScreen />}
    </ThemeProvider>
  );
};

export default RegisterPage;
