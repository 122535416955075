import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  Control,
  useFieldArray,
  useWatch,
  useFormContext,
} from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { ReusableTextField } from "../../reusable";

type Props = {
  control: Control<any>;
  isOpened: boolean;
  toggleOpen: (open: boolean) => void;
  name: string;
  type: "area" | "adjustment" | "percentage";
  placeholderTitle: string;
  placeholderDesc?: string;
  tableTitle: string;
  adornmentText: string;
  placeholderButtonText?: string;
  sumValue?: number;
  showBtn?: boolean;
};

export const AreaForm = ({
  control,
  name,
  isOpened,
  type,
  adornmentText,
  placeholderTitle,
  tableTitle,
  placeholderButtonText,
  sumValue,
  toggleOpen,
  showBtn = true,
  placeholderDesc = "This will alter target carbon value above.",
}: Props) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
  });

  const { setValue, setError, clearErrors } = useFormContext();

  const watchedFields = useWatch({
    control,
    name,
    defaultValue: fields,
  });

  const memoizedWatchedFields = useMemo(
    () => watchedFields || [],
    [watchedFields]
  );

  const [remainingValue, setRemainingValue] = useState(sumValue || 0);
  const [focusedField, setFocusedField] = useState<number | null>(null);

  useEffect(() => {
    if (fields.length !== 0) {
      toggleOpen(true);
    }
  }, [fields, toggleOpen]);

  useEffect(() => {
    if (sumValue && sumValue !== 0 && type === "area") {
      setRemainingValue(sumValue);
    }
  }, [sumValue, replace, type]);

  useEffect(() => {
    if (!sumValue || type !== "area") return;

    const total = memoizedWatchedFields.reduce((acc: number, value: any) => {
      const fieldValue = parseFloat(value || 0);
      return acc + fieldValue;
    }, 0);

    const remaining = sumValue - total;
    setRemainingValue(remaining);

    if (remaining >= 0) {
      clearErrors(name);
      return;
    }

    setRemainingValue(0);
    const overage = -remaining;
    for (let i = memoizedWatchedFields.length - 1; i >= 0; i--) {
      const fieldValue = parseFloat(memoizedWatchedFields[i] || 0);
      if (fieldValue <= 0) continue;

      const newFieldValue = fieldValue - overage;
      if (i === memoizedWatchedFields.length - 1) {
        setValue(`${name}.${i}`, newFieldValue > 0 ? newFieldValue : 0);
      } else {
        setError(`${name}.${i}`, {
          type: "manual",
          message: "Value exceeds the remaining limit.",
        });
        setValue(`${name}.${i}`, fieldValue);
      }
      break;
    }
  }, [
    memoizedWatchedFields,
    sumValue,
    setValue,
    setError,
    name,
    remove,
    type,
    clearErrors,
  ]);

  const handleAddNewField = () => {
    toggleOpen(true);
    append(0);
    clearErrors(name);
  };

  const handleRemoveField = (index: number) => {
    remove(index);
    clearErrors(name);
  };

  useEffect(() => {
    if (fields.length === 0) {
      toggleOpen(false);
    }
  }, [fields.length, toggleOpen]);

  if (!isOpened) {
    return (
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box
          sx={{ width: 314, height: 170, borderRadius: "10px", flexShrink: 0 }}
        >
          <img
            src="/assets/estimation-area-placeholder.png"
            alt="placeholder"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#EAAA08"
            fontFamily="Comfortaa, sans-serif"
          >
            {`Begin with your first ${placeholderTitle}`}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color="grey" width="300px">
            {placeholderDesc}
          </Typography>
          {showBtn && (
            <Box>
              <Button
                disableRipple
                endIcon={<AddRoundedIcon />}
                sx={{
                  mt: 1,
                  px: 2,
                  py: 1,
                  bgcolor: "#E5EFE8",
                  color: "#217A25",
                  textTransform: "none",
                  borderRadius: "15px",
                  "&:hover": {
                    bgcolor: "#c1d7c3",
                  },
                }}
                onClick={handleAddNewField}
              >
                <Typography fontSize={14} fontWeight={600} color="#217A25">
                  {placeholderButtonText}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography fontSize={14} fontWeight={600} mb={1}>
        {tableTitle}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 2,
          bgcolor: "#F9FAFB",
          border: "2px solid #EAECF0",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {fields.map((_: any, ind: number) => (
            <Box key={ind}>
              <Typography fontSize={14} fontWeight={600}>
                {type === "area"
                  ? `Area ${ind + 1}`
                  : type === "percentage"
                  ? `Year ${ind + 1}`
                  : `Adjustment ${ind + 1}`}
              </Typography>
              <Box sx={{ width: 320, display: "flex", alignItems: "center" }}>
                <ReusableTextField
                  control={control}
                  borderRadius="10px"
                  inputType="number"
                  placeholder="Enter value here"
                  name={`${name}.${ind}`}
                  startAdornment={
                    <Typography
                      sx={{
                        borderRight: "2px solid lightgrey",
                        paddingRight: "6px",
                        color: "grey",
                      }}
                    >
                      {adornmentText}
                    </Typography>
                  }
                  endAdornment={
                    focusedField === ind &&
                    sumValue !== undefined && (
                      <Typography
                        sx={{
                          borderLeft: "2px solid lightgrey",
                          paddingLeft: "6px",
                          color: "grey",
                        }}
                        fontSize={13}
                      >
                        {`${remainingValue.toFixed(2)} ${adornmentText} left`}
                      </Typography>
                    )
                  }
                  onFocus={() => setFocusedField(ind)}
                  onBlur={() => setFocusedField(null)}
                />
                {showBtn && (
                  <IconButton
                    disableTouchRipple
                    sx={{ ml: 1, mb: 1 }}
                    onClick={() => handleRemoveField(ind)}
                  >
                    <img
                      src="/assets/area-delete.svg"
                      alt="delete"
                      width={25}
                      height={25}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        {showBtn && (
          <Box>
            <Button
              disableRipple
              endIcon={<AddRoundedIcon />}
              sx={{
                mt: 1,
                px: 2,
                py: 1,
                bgcolor: "#E5EFE8",
                color: "#217A25",
                textTransform: "none",
                borderRadius: "15px",
                "&:hover": {
                  bgcolor: "#c1d7c3",
                },
              }}
              onClick={handleAddNewField}
              disabled={type === "area" && remainingValue <= 0}
            >
              <Typography fontSize={14} fontWeight={600} color="#217A25">
                {placeholderButtonText || "Add Estimation Area"}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AreaForm;
