import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CssBaseline, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLogInMutation } from "../api/users/logInMutation";
import { ReusableTextField, ReusableButton } from "../components/reusable";
import { loginSchema } from "../schemas/login";
import { customLoginTheme } from "../constants/login";

const LogInPage = () => {
  const { handleSubmit, control } = useForm({
    defaultValues: { identifier: "", password: "" },
    resolver: yupResolver(loginSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useLogInMutation();
  const navigate = useNavigate();

  const onSubmit = (value: { identifier: string; password: string }) => {
    if (isLoading) return;
    setIsLoading(true);
    mutate(value);
    setIsLoading(false);
  };
  return (
    <ThemeProvider theme={customLoginTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundImage: `url("/assets/login-screen-image.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: 0,
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "812px",
            height: "100vh",
            bgcolor: "background.paper",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "0",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ borderLeft: `4px solid #785134`, marginRight: "3px" }}
            />
            <div
              style={{ borderLeft: `4px solid #1F6F30`, marginRight: "3px" }}
            />
            <div style={{ borderLeft: `4px solid #FFAD01` }} />
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="No data loaded"
                src={"/assets/fresh-earth-logo.svg"}
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />
              <Typography
                component="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: "30px",
                  lineHeight: "38px",
                  marginTop: "20px",
                  marginBottom: "4px",
                }}
              >
                Log in to your account
              </Typography>
              <Typography
                component="h1"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  marginBottom: "14px",
                }}
              >
                Welcome back! Please enter your details.
              </Typography>
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                sx={{ mt: 1, width: "100%" }}
              >
                <ReusableTextField
                  inputType="email"
                  control={control}
                  name="identifier"
                  label="Email"
                  autoComplete="email"
                  isRequired={true}
                  autofocus={true}
                  placeholder="example@email.com"
                />
                <ReusableTextField
                  inputType="password"
                  control={control}
                  name="password"
                  label="Password"
                  autoComplete="password"
                  isRequired={true}
                />
                <Link to="/reset-password">
                  <Typography
                    component="h3"
                    sx={{
                      mb: 2,
                      textAlign: "center",
                      textDecoration: "none",
                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    Forgot your password?
                  </Typography>
                </Link>
                <ReusableButton
                  type="submit"
                  onClickFunction={handleSubmit(onSubmit)}
                  buttonText="Login"
                  width="100%"
                  isLoading={isLoading}
                />
              </Box>
            </Box>
          </Box>
          <div
            style={{
              position: "absolute",
              right: "0",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ borderLeft: `4px solid #FFAD01`, marginRight: "3px" }}
            />
            <div
              style={{ borderLeft: `4px solid #1F6F30`, marginRight: "3px" }}
            />
            <div style={{ borderLeft: `4px solid #785134` }} />
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LogInPage;
