import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, Typography } from "@mui/material";
import { ChangeEvent, DragEvent, RefObject, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { formatBytes } from "../../../utils/sizeConverting";

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
}

interface Data {
  name: string;
  size: number;
  value: string | undefined;
}

const setFile = (
  e: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
  files: FileList | null,
  onChange: (o: any[]) => void,
  value: Data[],
  inputRef: RefObject<HTMLInputElement>
) => {
  e.preventDefault();
  e.stopPropagation();
  const file = files && files[0] ? files[0] : null;
  if (inputRef.current?.value) {
    inputRef.current.value = "";
  }
  if (file) {
    const reader = new FileReader();

    reader.onload = function () {
      const fileToAdd = {
        name: file.name,
        size: file.size,
        value: reader.result,
      };
      onChange([...value, fileToAdd]);
    };

    reader.readAsDataURL(file);
  }
};

// drag drop file component
export default function ReusableFileUpload({ control, name, label }: Props) {
  // drag state
  const [, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDelete = (
    chipToDelete: Data,
    onChange: (o: any[]) => void,
    value: Data[]
  ) => {
    onChange(value?.filter((chip) => chip.value !== chipToDelete.value));
  };

  // handle drag events
  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (
    e: DragEvent<HTMLDivElement>,
    onChange: (o: any[]) => void,
    value: Data[]
  ) {
    setFile(e, e.dataTransfer.files, onChange, value, inputRef);
  };

  // triggers when file is selected with click
  const handleChange = function (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (o: any[]) => void,
    value: Data[]
  ) {
    setFile(e, e.target.files, onChange, value, inputRef);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = [] } }) => {
        return (
          <FormControl fullWidth sx={{ margin: "8px 0 8px 0" }}>
            {label && (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "left",
                  marginBottom: "2px",
                }}
              >
                {label}
              </Typography>
            )}
            <Box
              style={{ display: "flex", padding: 3 }}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={(e) => handleDrop(e, onChange, value)}
            >
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                multiple={true}
                onChange={(e) => handleChange(e, onChange, value)}
              />
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: "2px",
                  borderRadius: "1rem",
                  backgroundColor: "#f8fafc",
                  padding: 25,
                }}
                htmlFor="input-file-upload"
              >
                <img src="/assets/upload-icon.png" alt="Upload" />
                <Box sx={{ display: "flex", gap: 1, paddingX: 12 }}>
                  <button
                    type="button"
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={onButtonClick}
                  >
                    <Typography variant="subtitle1" color="success.main">
                      Click to upload
                    </Typography>
                  </button>
                  <Typography variant="subtitle1">or drag and drop</Typography>
                </Box>
                <Typography variant="subtitle2" color="text.secondary">
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </Typography>
              </label>
            </Box>
            {value && value.length > 0 ? (
              <Box sx={{ display: "flex", gap: 3, mt: 5 }}>
                {value?.map((d: Data, i: number) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        borderRadius: 3,
                        bgcolor: "#FFFFFF",
                        padding: 1,
                        gap: 3,
                        borderWidth: "2px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 3 }}>
                        <img src="/assets/file-icon.png" alt="File" />
                        <Box>
                          <Typography variant="body2">{d.name}</Typography>
                          <Typography variant="body2">
                            {formatBytes(d.size)}
                          </Typography>
                        </Box>
                        <CloseIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(d, onChange, value)}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <></>
            )}
          </FormControl>
        );
      }}
    />
  );
}
