import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { Tenant } from "../../types/tenant";

export function useAllTenantsQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/tenants/all"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_MC_URL}/api/tenants`, {
        headers: tokenHeaders,
      }).then(parseJsonResponse<Tenant[]>),
    enabled: hasToken,
  });
}
