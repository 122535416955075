import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import { useAuthToken } from "../../auth/authToken";
import { useMainPageQuery } from "../../api/pages/mainPage";

interface NavItem {
  id: string;
  name: string;
  role?: { type: string }[];
}

const roleInRoles = (roles: string[] | undefined, role: string | undefined) => {
  if (!role) return false;
  return roles?.includes(role);
};

const Header: React.FC = () => {
  const { data } = useMainPageQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();
  const role = me?.role.type;

  const handleOnIconClick = () => {
    if (!hasToken) {
      navigate("login");
    } else {
      navigate("profile/account-details");
    }
  };

  if (!data) return null;

  const dataNavigation = data.navigation;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        height: "60px",
        backgroundColor: "white",
        zIndex: 201,
        borderBottom: "2px solid #ccc",
        display: location.pathname === "/login" ? "none" : "flex",
        flexDirection: "row",
        width: "100%",
      }}
      className="main-navbar"
    >
      <div
        style={{
          borderRadius: "20px",
          flexDirection: "row",
          zIndex: 201,
          display: "flex",
          height: "100%",
          width: "70%",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: location.pathname === "/" ? "underline" : "none",
            textDecorationColor: location.pathname === "/" ? "#FFB61B" : "none",
            color: location.pathname === "/" ? "#367E46" : "#1F1F1F",
          }}
        >
          <img
            height="80"
            width="150"
            alt="No data loaded"
            src={dataNavigation?.nav_logo.formats.small.url}
          />
        </Link>
        {hasToken &&
          dataNavigation?.nav_item.map((item: NavItem) => {
            const isOnPage =
              location.pathname.split("/")[1] === item.name.toLocaleLowerCase();
            const listOfRoles = item.role?.map((a) => a.type);
            if (!roleInRoles(listOfRoles, role)) {
              return null;
            }
            return (
              <Link
                to={item.name.toLocaleLowerCase()}
                key={item.id}
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: isOnPage ? "#1F7031" : "transparent",
                  color: isOnPage ? "#ffff" : "#344054",
                  borderRadius: "6px",
                  margin: "20px",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  userSelect: "none",
                }}
              >
                {item.name}
              </Link>
            );
          })}
        {hasToken && (
          <Link
            to={"/projects"}
            key={"projects"}
            style={{
              padding: "8px 12px 8px 12px",
              backgroundColor: location.pathname.includes("/projects")
                ? "#1F7031"
                : "transparent",
              color: location.pathname.includes("/projects")
                ? "#ffff"
                : "#344054",
              borderRadius: "6px",
              margin: "20px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              userSelect: "none",
            }}
          >
            Projects
          </Link>
        )}
        {hasToken && (
          <Link
            to={"/programs"}
            key={"programs"}
            style={{
              padding: "8px 12px 8px 12px",
              backgroundColor: location.pathname.includes("/programs")
                ? "#1F7031"
                : "transparent",
              color: location.pathname.includes("/programs")
                ? "#ffff"
                : "#344054",
              borderRadius: "6px",
              margin: "20px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              userSelect: "none",
            }}
          >
            Programs
          </Link>
        )}
        {hasToken && (
          <Link
            to={"/assistant-chat"}
            key={"assistant"}
            style={{
              padding: "8px 12px 8px 12px",
              backgroundColor: location.pathname.includes("/assistant-chat")
                ? "#1F7031"
                : "transparent",
              color: location.pathname.includes("/assistant-chat")
                ? "#ffff"
                : "#344054",
              borderRadius: "6px",
              margin: "20px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              userSelect: "none",
            }}
          >
            Assistant
          </Link>
        )}
        {hasToken && (
          <Link
            to={"/collab"}
            key={"collab"}
            style={{
              padding: "8px 12px 8px 12px",
              backgroundColor: location.pathname.includes("/collab")
                ? "#1F7031"
                : "transparent",
              color: location.pathname.includes("/collab")
                ? "#ffff"
                : "#344054",
              borderRadius: "6px",
              margin: "20px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              userSelect: "none",
            }}
          >
            Collaboration
          </Link>
        )}
        {hasToken && (
          <Box
            style={{
              padding: "8px 12px 8px 12px",
              backgroundColor: "transparent",
              color: "#344054",
              borderRadius: "6px",
              margin: "20px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              userSelect: "none",
            }}
          >
            <a
              href="https://fresh-earth.helloagain.com.au/market"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
            </a>
          </Box>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "30%",
          justifyContent: "right",
          marginRight: "20px",
          height: "100%",
          left: 0,
        }}
      >
        <Button
          disableFocusRipple
          disableElevation={true}
          id="basic-button"
          aria-haspopup="true"
          onClick={handleOnIconClick}
          style={{
            borderRadius: "50px",
          }}
        >
          <img
            height="50"
            width="35"
            style={{
              border: "1px solid #555",
              borderRadius: "50px",
              cursor: "pointer",
            }}
            alt="No data loaded"
            src={"/assets/profile-avatar-icon.svg"}
          />
        </Button>
      </div>
    </div>
  );
};

export default Header;
