import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { displayCSV } from "../../../../utils/program";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { ReusableTextField } from "../../../reusable";
//import ApplyDataFromFarmlab from "../../widgets/ApplyDataFromFarmlab";
import AreaForm from "../../widgets/AreaForm";

export const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "grey",
      }}
    >
      {text}
    </Typography>
  );
};

const SoilCarbonEstimation = ({ farmName }: { farmName: string }) => {
  const { control, watch, setValue } = useFormContext();
  const [isAreaFormOpened, setIsAreaFromOpened] = useState<boolean>(false);

  const soilCarbonEstimationAreasWatch = watch("soilCarbonEstimationAreas");
  const siteCarbonAdjustmentsWatch = watch("siteCarbonAdjustments");

  const soilCarbonArea = Number(watch("soilCarbonArea") || 0);
  const soilCarbonAreaExclusionZone = Number(
    watch("soilCarbonAreaExclusionZone") || 0
  );

  const soilCarbonEstimationAreaAdjusted =
    soilCarbonArea * (1 - soilCarbonAreaExclusionZone / 100);

  const toggleOpenAreaForm = (open: boolean) => {
    setIsAreaFromOpened(open);
  };

  const soilCarbonEstimationAreas = useMemo(() => {
    return soilCarbonEstimationAreasWatch || [];
  }, [soilCarbonEstimationAreasWatch]);

  const siteCarbonAdjustments = useMemo(() => {
    return siteCarbonAdjustmentsWatch || [];
  }, [siteCarbonAdjustmentsWatch]);

  useEffect(() => {
    if (soilCarbonArea <= 0 || soilCarbonAreaExclusionZone >= 100) {
      setValue("siteCarbonAdjustments", []);
      setValue("soilCarbonEstimationAreaAdjusted", []);
      return;
    }
    let res: number[] = siteCarbonAdjustments;
    if (soilCarbonEstimationAreas.length === siteCarbonAdjustments.length) {
      return;
    }
    if (soilCarbonEstimationAreas.length < siteCarbonAdjustments.length) {
      res = siteCarbonAdjustments.slice(0, soilCarbonEstimationAreas.length);
    } else {
      res = [...res, 0];
    }
    setValue("siteCarbonAdjustments", res);
  }, [
    setValue,
    soilCarbonEstimationAreas.length,
    siteCarbonAdjustments,
    soilCarbonArea,
    soilCarbonAreaExclusionZone,
  ]);

  useEffect(() => {
    setValue(
      "soilCarbonEstimationAreaAdjusted",
      soilCarbonEstimationAreaAdjusted
    );
  }, [soilCarbonEstimationAreaAdjusted, setValue]);

  useEffect(() => {
    if (soilCarbonArea > 0) {
      return;
    }
    setValue("soilCarbonEstimationAreas", []);
  }, [soilCarbonArea, setValue]);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader
          title="Soil Carbon Estimation Area"
          subtitle="placeholder"
          // rightSideAction={<ApplyDataFromFarmlab />}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 3,
        }}
      >
        <Box>
          <Typography fontSize={14} fontWeight={400}>
            Farm Name
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            {farmName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="soilCarbonArea"
            label="Soil Carbon Area"
            startAdornment={<InputAdornment text="ha" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="soilCarbonAreaExclusionZone"
            label="Soil Carbon Area - Exclusion zone"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography fontSize={14} fontWeight={400} mt={2}>
            Soil Carbon Estimation Area (Net) - Adjusted
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            {`${displayCSV(soilCarbonEstimationAreaAdjusted)} ha`}
          </Typography>
        </Box>
        <AreaForm
          isOpened={isAreaFormOpened}
          toggleOpen={toggleOpenAreaForm}
          control={control}
          sumValue={soilCarbonEstimationAreaAdjusted}
          name="soilCarbonEstimationAreas"
          type="area"
          placeholderTitle="Soil Carbon Estimation Area"
          tableTitle="Soil Carbon Estimation Area (Net)"
          adornmentText="ha"
          placeholderButtonText="Add Soil Carbon Estimation Area"
          showBtn={soilCarbonArea > 0 && soilCarbonAreaExclusionZone < 100}
        />
      </Box>
    </Box>
  );
};

export default SoilCarbonEstimation;
