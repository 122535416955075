import * as yup from "yup";

export const defaultCountry = {
  code: "AU",
  label: "Australia",
  phone: "+61",
};

export const farmerOBP1Schema = yup
  .object()
  .shape({
    "UD-I1-FN": yup.string().nullable(),
    "UD-I1-LN": yup.string().nullable(),
    "UD-I2": yup.string().email("Email must be valid").nullable(),
    "UD-I3": yup.string().nullable(),
    "UD-I4": yup.string().nullable(),
    "UD-I5": yup.string().nullable()
  })
  .required();

export const farmerOBP1DefaultValues = {
  "UD-I1-FN": "",
  "UD-I1-LN": "",
  "UD-I2": "",
  "UD-I3": "",
  "UD-I4": "",
  "UD-I5": ""
};

export const farmerOBP2Schema = yup
  .object()
  .shape({
    "FJ-G1": yup.array().nullable(),
    "FJ-G2": yup.array().nullable(),
    "FJ-V1": yup.string().nullable(),
    "FJ-V2": yup.array().nullable(),
    "FJ-D1": yup.string().nullable(),
    "FJ-D2": yup.string().nullable(),
    "FJ-E1": yup.string().nullable(),
    "FJ-C1": yup.array().nullable(),
    "FM-SP1": yup.string().nullable(),
    "2-FM-SP1-1-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-2-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-2-FM-SP1": yup.array().nullable(),
    "2-FM-SP1-3-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-3-FM-SP1": yup.array().nullable(),
    "IK-1": yup.string().nullable(),
  })
  .required();

export const farmerOBP2DefaultValues = {
  "FJ-G1": [],
  "FJ-G2": [],
  "FJ-V1": "",
  "FJ-V2": [],
  "FJ-D1": "",
  "FJ-D2": "",
  "FJ-E1": "",
  "FJ-C1": [],
  "FM-SP1": "",
  "2-FM-SP1-1-FJ-C1": [],
  "2-FM-SP1-2-FJ-C1": [],
  "2-FM-SP1-2-FM-SP1": [],
  "2-FM-SP1-3-FJ-C1": [],
  "2-FM-SP1-3-FM-SP1": [],
  "IK-1": "",
};

export const farmerOBP3Schema = yup
  .object()
  .shape({
    "UD-I1": yup.string().nullable(),
    "UD-I2": yup.string().nullable(),
    "UD-I3": yup.string().nullable(),
    "UD-MA1": yup.array().nullable(),
    "UD-MA3": yup.array().nullable(),
    "UD-MA2": yup.string().nullable(),
    "FJ-E1": yup.array().nullable(),
  })
  .required();

export const farmerOBP3DefaultValues = {
  "UD-I1": "",
  "UD-I2": "",
  "UD-I3": "",
  "UD-MA1": [],
  "UD-MA3": [],
  "UD-MA2": "",
  "FJ-E1": [],
};

export const farmerOBSchema = yup
  .object()
  .shape({
    processOneData: farmerOBP1Schema,
    processTwoData: farmerOBP2Schema,
    processThreeData: farmerOBP3Schema,
  })
  .required();
